<template>
  <div>
    <div class="text-h4 pt-5 mb-5">Партнерская программа</div>
    <partners-table></partners-table>
  </div>
</template>

<script>
import partnersTable from "@/components/Partners/PartnersTable";

export default {
  name: "Partners",
  components: { partnersTable },
};
</script>

<style scoped></style>
