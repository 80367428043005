<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="1"
      :loading="loading"
      show-expand
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <template>
            <v-card class="pa-1">
              <v-card-title
                class="text-h5 text-center justify-center flex-column mb-5"
                >Удалить категорию?<br />
                <span class="accent--text">{{
                  itemCurrentName
                }}</span></v-card-title
              >

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="closeDelete"
                  >Отмена</v-btn
                >
                <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
      <template v-slot:item.partner="{ value }">
        {{ value.firstname }} {{ value.lastname }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4">
          <v-simple-table
            v-if="item.linked_users.length"
            class="mb-5"
            dense
            :height="item.linked_users.length > 8 ? 288 : 'auto'"
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Email</th>
                  <th class="text-left">Пользователь</th>
                  <th class="text-left">Дата регистрации</th>
                  <th class="text-left">Роль</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invite, ind) in item.linked_users"
                  :key="`invite_${ind}`"
                  :class="{ 'is-disabled': invite.disabled }"
                >
                  <td>
                    {{ invite.email }}
                    <span v-if="invite.disabled"
                      >(<b class="error--text">удален</b>)</span
                    >
                  </td>
                  <td>{{ invite.firstname }} {{ invite.lastname }}</td>
                  <td>
                    {{ moment(invite.created_at).format("YYYY-MM-DD HH:mm") }}
                  </td>
                  <td>{{ invite.roles.join(", ") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "../../../api";
import moment from "moment";

export default {
  name: "PartnersTable",
  data() {
    return {
      dialogDelete: false,
      headers: [
        {
          text: "URL",
          align: "start",
          sortable: false,
          value: "public_link",
        },
        {
          text: "Партнер",
          align: "start",
          sortable: false,
          value: "partner",
        },
        {
          text: "Приглашений",
          align: "center",
          sortable: false,
          value: "linked_users_count",
          width: 120,
        },
        // {
        //   text: "Действия",
        //   value: "actions",
        //   sortable: false,
        //   align: "end",
        // },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      optionsTable: {},
      total: 0,
      loading: true,
      errors: {},
      referrals: null,
      invites: null,
      updateInterval: null,
    };
  },

  computed: {
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].name : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  created() {
    this.updateInterval = setInterval(() => this.getItems(), 1000 * 60 * 5);
  },
  beforeDestroy() {
    this.updateInterval = clearInterval(this.updateInterval);
  },
  methods: {
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
        this.errors = {};
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable;
      try {
        const req = await api.partners.partnerLinks({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.items[this.editedIndex].name;
        await api.articles.deleteTag(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Тег "${nameDelete}" удален`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },
    moment(date) {
      return moment(date);
    },
  },
};
</script>

<style lang="scss">
.v-data-table {
  tr.is-disabled {
    background-color: #eee !important;
    position: relative;
    td {
      background-color: #eee !important;
      opacity: 0.6;
    }
  }
}
</style>
